import api from '@/services/api/api';

const defaultOptions = {
  staleTime: Infinity,
  refetchOnFocus: false,
  refetchOnReconnect: false,
};

export const useFilmsMaterials = ({ options }) => {
  const filmsMaterials = useQuery(
    ['filmsMaterials'],
    async () => await api.lectures.getRandomVideoMaterials(options?.count),
    { ...defaultOptions, ...options, select: ({ data }) => data?.data },
  );

  filmsMaterials.suspense();

  return {
    filmsMaterials,
  };
};
